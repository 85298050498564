import { Injectable } from '@angular/core';
import { ApiHttpService, ApiIndexResult, ApiService, ListOptions } from '@capturum/api';
import { Observable } from 'rxjs';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';
import { MapItem } from '@capturum/ui/api';
import { Product } from '@core/models/product.model';
import { BaseKey } from '@core/enums/base-key.enum';
import { BaseDataIndexedDbModel } from '@capturum/complete';
import { DxpIndexableBaseDataService } from '@core/indexDb/services/dxp-indexable-base-data.service';
import { DisplayEntity, Entity } from '@core/enums/entity.enum';
import { GradingsService } from '@core/api/gradings.service';
import { Grading, GradingFloricodeFeature } from '@core/models/grading.model';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CharacteristicsService extends ApiService<Product> {
  constructor(
    public apiHttp: ApiHttpService,
    private dxpIndexableBaseDataService: DxpIndexableBaseDataService,
    private gradingsService: GradingsService,
  ) {
    super(apiHttp);
  }

  public getTypesByCharacteristicGroup(characteristicGroupId: string, options?: ListOptions): Observable<MapItem[]> {
    let url = `/characteristic-group/${characteristicGroupId}/types`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get<ApiIndexResult<MapItem>>(url).pipe(
      map((result) => {
        return result?.data;
      }),
    );
  }

  public getValuesByType(groupKey: string, options?: ListOptions): Observable<MapItem[]> {
    let url = `/characteristic-type/${groupKey}/values`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get<ApiIndexResult<MapItem>>(url).pipe(
      map((result) => {
        return result?.data;
      }),
    );
  }

  public getAdjustingProcessByKey(adjustingProcessName: Entity | DisplayEntity): Observable<MapItem> {
    return this.getAdjustingProcessBaseData().pipe(
      first(),
      map((adjustingProcesses) => {
        return adjustingProcesses.find((adjustingProcess) => {
          return adjustingProcess.key === adjustingProcessName;
        });
      }),
    );
  }

  public fetchCharacteristics(gradingId: string, entity: Entity | DisplayEntity): Observable<Grading> {
    if (gradingId) {
      return this.getAdjustingProcessByKey(entity).pipe(
        switchMap((adjustingProcess) => {
          const options: ListOptions = {
            include: [
              'gradingFloricodeFeature.floricodeFeatureValue',
              'gradingFloricodeFeature.productFloricodeFeature.floricodeFeatureType',
              'gradingFloricodeFeature.gradingFloricodeFeatureValues.floricodeFeatureValue',
            ],
          };

          return this.gradingsService.get(gradingId, options)?.pipe(
            map((grading) => {
              grading?.gradingFloricodeFeature?.map((feature) => {
                if (!feature.floricodeFeatureValue) {
                  feature.floricodeFeatureValue = { id: '' } as any;
                }

                return feature.floricodeFeatureValue;
              });

              return grading;
            }),
          );
        }),
      );
    }
  }

  public mapCharacteristics(floricodeFeatures: any): GradingFloricodeFeature[] {
    const characteristics = floricodeFeatures?.grading?.gradingFloricodeFeature;

    return characteristics?.map((gradingFloricodeFeature) => {
      let gradingFloricodeFeatureData = gradingFloricodeFeature;
      const modelFloricodeFeatures =
        floricodeFeatures?.modelFloricodeFeatures.find((item) => {
          return item?.productFloricodeFeature?.id === gradingFloricodeFeatureData?.product_floricode_feature_id;
        }) || {};

      if (
        (modelFloricodeFeatures && !isEmpty(modelFloricodeFeatures)) ||
        gradingFloricodeFeatureData.floricodeFeatureValue === null
      ) {
        if (!modelFloricodeFeatures.floricodeFeatureValue) {
          modelFloricodeFeatures.floricodeFeatureValue = { id: '' } as any;
        }

        gradingFloricodeFeatureData = {
          ...gradingFloricodeFeatureData,
          floricode_feature_value_id: modelFloricodeFeatures.floricode_feature_value_id,
          floricodeFeatureValue: modelFloricodeFeatures.floricodeFeatureValue,
        };
      }

      return gradingFloricodeFeatureData;
    });
  }

  private getAdjustingProcessBaseData(): Observable<MapItem[]> {
    return this.dxpIndexableBaseDataService.getBaseDataByKey(BaseKey.adjustingProcess).pipe(
      mergeMap((baseDataKey: BaseDataIndexedDbModel) => {
        return this.dxpIndexableBaseDataService.getConvertedBaseDataValues({
          include: ['attributes', 'translations'],
          filters: [
            {
              field: 'base_data_key_id',
              value: baseDataKey?.id,
            },
          ],
        });
      }),
    );
  }
}
